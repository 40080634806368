<template>
  <div class="app">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
          >首页
          </el-breadcrumb-item
          >
          <el-breadcrumb-item>商家联盟</el-breadcrumb-item>
          <el-breadcrumb-item>门店信息</el-breadcrumb-item>
          <el-breadcrumb-item>门店修改</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <el-form ref="form" :model="infoDetail">
      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <el-col :span="6">
            <div class="sub-title">门店名称</div>
            <el-input
                placeholder="门店名称"
                v-model="infoDetail.shopName"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <div class="sub-title">营业电话</div>
            <el-input
                placeholder="营业电话"
                v-model="infoDetail.businessPhone"
            ></el-input>
          </el-col>
          <!--          <el-col :span="6">-->
          <!--            <div class="sub-title">经营类型</div>-->
          <!--            <el-input-->
          <!--                placeholder=""-->
          <!--                v-model="infoDetail.categoryName"-->
          <!--                :disabled="true"-->
          <!--            ></el-input>-->
          <!--          </el-col>-->
          <!--          <el-col :span="6">-->
          <!--            <div class="sub-title">所属区域</div>-->
          <!--            <el-input-->
          <!--                placeholder=""-->
          <!--                v-model="infoDetail.shopAreaCode"-->
          <!--                :disabled="true"-->
          <!--            ></el-input>-->
          <!--          </el-col>-->
        </el-row>
        <!--        <el-row :gutter="40">-->
        <!--          <el-col :span="24">-->
        <!--            <span class="grid-divider">来源/推荐</span>-->
        <!--            <el-divider></el-divider>-->
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <!--            <div class="sub-title">开发者账号</div>-->
        <!--            <el-input-->
        <!--                placeholder=""-->
        <!--                v-model="infoDetail.openShopUserAccount"-->
        <!--                :disabled="true"-->
        <!--            ></el-input>-->
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <!--            <div class="sub-title">开发者名称</div>-->
        <!--            <el-input-->
        <!--                placeholder=""-->
        <!--                v-model="infoDetail.openShopUserRealName"-->
        <!--                :disabled="true"-->
        <!--            ></el-input>-->
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <!--            <div class="sub-title">开发者ID</div>-->
        <!--            <el-input-->
        <!--                placeholder=""-->
        <!--                v-model="infoDetail.openShopUserId"-->
        <!--                :disabled="true"-->
        <!--            ></el-input>-->
        <!--          </el-col>-->
        <!--          <el-col :span="6"> </el-col>-->
        <!--        </el-row>-->
        <!--        <el-row :gutter="40">-->
        <!--          <el-col :span="24">-->
        <!--            <span class="grid-divider">证件信息</span>-->
        <!--            <el-divider></el-divider>-->
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <!--            <div class="sub-title">经营许可证</div>-->
        <!--            <el-image-->
        <!--                class="el-imag"-->
        <!--                :src="-->
        <!--                  infoDetail.businessCertificateList-->
        <!--                    ? infoDetail.businessCertificateList[0]-->
        <!--                    : ''-->
        <!--                "-->
        <!--                :preview-src-list="infoDetail.businessCertificateList"-->
        <!--                fit="contain"-->
        <!--            >-->
        <!--              <div slot="error" class="image-slot">-->
        <!--                <i class="el-icon-picture-outline"></i>-->
        <!--              </div>-->
        <!--            </el-image>-->
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <!--            <div class="sub-title">门头照片</div>-->
        <!--            <el-image-->
        <!--                class="el-imag"-->
        <!--                :src="infoDetail.shopHeadImage ? infoDetail.shopHeadImage : ''"-->
        <!--                fit="contain"-->
        <!--            >-->
        <!--              <div slot="error" class="image-slot">-->
        <!--                <i class="el-icon-picture-outline"></i>-->
        <!--              </div>-->
        <!--            </el-image>-->
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <!--            <div class="sub-title">店内环境照</div>-->
        <!--            <el-image-->
        <!--                class="el-imag"-->
        <!--                :src="-->
        <!--                  infoDetail.shopImageList ? infoDetail.shopImageList[0] : ''-->
        <!--                "-->
        <!--                :preview-src-list="infoDetail.shopImageList"-->
        <!--                fit="contain"-->
        <!--            >-->
        <!--              <div slot="error" class="image-slot">-->
        <!--                <i class="el-icon-picture-outline"></i>-->
        <!--              </div>-->
        <!--            </el-image>-->
        <!--          </el-col>-->
        <!--          <el-col :span="6"> </el-col>-->
        <!--        </el-row>-->
        <!--        <el-row :gutter="40">-->
        <!--          <el-col :span="24">-->
        <!--            <span class="grid-divider">提现账号</span>-->
        <!--            <el-divider></el-divider>-->
        <!--          </el-col>-->
        <!--          <el-col>-->
        <!--            <el-row>-->
        <!--              <el-col-->
        <!--                  :span="6"-->
        <!--                  v-for="(row, i) in infoDetail.merchantBankList"-->
        <!--                  :key="i"-->
        <!--                  style="padding: 20px 5px; border: 1px dashed #d9d9d9"-->
        <!--              >-->
        <!--                <el-col :span="6" style="padding: 10px; text-align: center">-->
        <!--                  <el-avatar :size="50" src=""></el-avatar>-->
        <!--                </el-col>-->
        <!--                <el-col :span="18" style="font-size: 14px">-->
        <!--                  <h3 class="layadmin-title">-->
        <!--                    <strong>{{ row.bankName }}</strong>-->
        <!--                  </h3>-->
        <!--                  <p class="layadmin-textimg">卡号：{{ row.bankNo }}</p>-->
        <!--                  <div class="layadmin-address">储蓄卡</div>-->
        <!--                </el-col>-->
        <!--              </el-col>-->
        <!--            </el-row>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <el-divider></el-divider>
        <div style="text-align: left">
          <el-button icon="el-icon-save" type="primary" @click="saveShop">保存</el-button>
          <el-button icon="el-icon-back" type="primary" @click="backShop">返回</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      infoDetail: {
        shopId: this.$route.query.shopId,
      },
    };
  },
  created() {
    this.query = this.$route.query;
    this.infoData();
  },
  methods: {
    infoData() {
      this.$get(
          this.$api.systemShopDetail + "/" + this.$route.query.shopId,
      ).then((res) => {
        this.infoDetail = res.data;
      });
    },
    saveShop() {
      console.log({
        shopId: this.$route.query.shopId,
        shopName: this.infoDetail.shopName,
        businessPhone: this.infoDetail.businessPhone,
      });
      this.$post(this.$api.systemShopUpdateById, {
        id: this.$route.query.shopId,
        shopName: this.infoDetail.shopName,
        businessPhone: this.infoDetail.businessPhone,
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.$message.success("修改成功");
        }
      })
    },
    backShop(){
      this.$router.go(-1);
    },
  }
}
</script>